<template>
  <teleport to="#modals">
    <vue-resizable
      v-slot="{ style }"
      class="z-[100]"
      drag-selector=".cursor-grab"
      v-bind="emailOptions"
      :min-width="emailWidthAndHeight.minWidth"
      :min-height="emailWidthAndHeight.minHeight"
      @mount="updatePosition"
      @resize:end="updatePosition"
      @drag:end="updatePosition"
    >
      <div
        ref="resizableElement"
        :style="style"
        class="bg-white email-dialog rounded-md shadow-lg"
        :class="[isMinimized ? 'max-w-55' : 'h-full', modalClass]"
        data-cy="email-dialog"
      >
        <div
          class="bg-grey-navigation text-white p-4 rounded-t-md flex justify-between space-x-2"
          :class="{ 'rounded-b-md max-w-55': isMinimized }"
          data-cy="email-dialog-header"
        >
          <div
            class="flex flex-grow items-center space-x-4 min-w-0"
            data-cy="handle"
          >
            <icon-base
              :icon="IconCardView"
              width="16"
              height="16"
              view-box="0 0 24 24"
              class="transform rotate-90 cursor-grab handle min-w-4"
            />
            <span
              class="font-semibold grow text-white cursor-grab text-ellipsis whitespace-nowrap overflow-hidden"
              @dblclick="toggleMinimize"
            >
              {{ $t("COMMON.EMAIL") }}{{ dealName ? ` - ${dealName}` : "" }}
            </span>
          </div>
          <div class="flex space-x-2">
            <icon-base
              v-if="isMinimized"
              :icon="IconWidget"
              view-box="0 0 16 14"
              icon-name="Restore"
              :title="$t('COMMON.RESTORE')"
              class="text-white cursor-pointer"
              data-cy="maximize-email-dialog"
              @click="toggleMinimize"
            />
            <icon-base
              v-else
              :icon="IconSubtract"
              view-box="0 -5 20 20"
              icon-name="Minimize"
              :title="$t('COMMON.MINIMIZE')"
              class="text-white cursor-pointer"
              data-cy="minimize-email-dialog"
              @click="toggleMinimize"
            />
            <icon-base
              icon="x"
              icon-name="Close"
              class="cursor-pointer text-white"
              @click="emit('close-dialog')"
            />
          </div>
        </div>
        <loader :is-loading="loading" />
        <slot name="email-form">
          <!-- to prevent dynamic fields from not being popluated-->
          <email-form
            v-if="!loading"
            v-show="!isMinimized"
            @close-dialog="emit('close-dialog')"
          />
        </slot>
      </div>
    </vue-resizable>
  </teleport>
</template>

<script setup lang="ts">
import { ref, computed, onMounted } from "vue";
import type { EmailTemplateOptions } from "@/models/options";
import { useStore } from "vuex";
import { useLocalStorageSetting } from "@/hooks/options";
import useEmailsStore from "@/stores/emails";
import IconSubtract from "@/components/icons/IconSubtract.vue";
import IconWidget from "@/components/icons/IconWidget.vue";
import IconCardView from "@/components/icons/IconCardView.vue";
import EmailForm from "@/components/emailSending/EmailForm.vue";
import VueResizable from "vue-resizable";
import type { ResizableEventValues } from "@/models/common";
import isEmpty from "lodash/isEmpty";
import { DEFAULT_EMAIL_MODAL_OPTIONS } from "@/helpers/constants/deals";
import { convertToArray } from "@/helpers/common";
import { usePromiseWrapper } from "@/hooks/common";
import { useRoute } from "vue-router";

const emit = defineEmits<{
  "close-dialog": [];
}>();

const props = withDefaults(
  defineProps<{
    initialValues: { x: number; y: number };
    dealId: string | string[];
    anchorBottomLeft?: boolean;
    dealName?: string | undefined;
  }>(),
  {
    initialValues: () => ({ x: 0, y: 0 }),
    dealId: "",
    anchorBottomLeft: false,
    dealName: ""
  }
);

const { getters, dispatch } = useStore();
const store = useEmailsStore();
const route = useRoute();

const emailOptions = useLocalStorageSetting(
  "emailModalOptions",
  DEFAULT_EMAIL_MODAL_OPTIONS
);
const emailWidthAndHeight = ref({
  width: DEFAULT_EMAIL_MODAL_OPTIONS.width,
  height: DEFAULT_EMAIL_MODAL_OPTIONS.height,
  minWidth: 500,
  minHeight: 400,
  minimizedWidth: 220,
  minimizedHeight: 53
});

const isMinimized = ref(false);
const resizableElement = ref<HTMLElement | null>(null);

const emailTemplatesOptions = computed<EmailTemplateOptions | null>(
  () => getters["options/emailTemplates"]
);

const modalClass = computed(() => (route.params.id ? "deal-modal" : ""));

const initPosition = (position: { x: number; y: number }) => {
  const emailWidth = emailWidthAndHeight.value.width;
  const emailHeight = emailWidthAndHeight.value.height;
  const emailLeft = props.anchorBottomLeft
    ? position.x + emailWidth
    : position.x;
  const emailTop = props.anchorBottomLeft
    ? position.y - emailHeight
    : position.y;
  updatePosition(
    {
      left: emailLeft,
      top: emailTop,
      width: emailWidthAndHeight.value.width,
      height: emailWidthAndHeight.value.height
    },
    true
  );
};

const updatePosition = (
  positionAndEvent: ResizableEventValues,
  init = false
) => {
  const initOffset = init ? resizableElement.value?.clientWidth || 0 : 0;
  emailOptions.value = {
    ...emailOptions.value,
    left: positionAndEvent.left - initOffset,
    top: positionAndEvent.top < 0 ? 0 : positionAndEvent.top,
    width: positionAndEvent.width,
    height: positionAndEvent.height
  };
};

const toggleMinimize = () => {
  if (!isMinimized.value) {
    emailOptions.value.restoreHeight = emailOptions.value.height;
    emailOptions.value.restoreWidth = emailOptions.value.width;
  }
  isMinimized.value = !isMinimized.value;
  const width = isMinimized.value
    ? emailWidthAndHeight.value.minimizedWidth
    : emailOptions.value.restoreWidth || emailWidthAndHeight.value.width;
  const height = isMinimized.value
    ? emailWidthAndHeight.value.minimizedHeight
    : emailOptions.value.restoreHeight || emailWidthAndHeight.value.height;
  updatePosition({
    left: emailOptions.value.left,
    top: emailOptions.value.top,
    width,
    height
  });
};

const { fetchWrapper: getEmailsInfo, loading } = usePromiseWrapper(async () => {
  const promises = [
    store.getApplicationEmailModalData(convertToArray(props.dealId))
  ];
  if (isEmpty(emailTemplatesOptions.value)) {
    await dispatch("options/getEmailTemplatesOptions");
    promises.push(dispatch("options/getEmailTemplates"));
  }
  await Promise.all(promises);
  loading.value = false;
  if (!emailOptions.value.left && !emailOptions.value.top) {
    initPosition(props.initialValues);
  }
});

onMounted(getEmailsInfo);
</script>
